import * as React from 'react';
import { Component } from 'react';
import { Request } from '../../Components/Request/Request';
import config from '../../config';

const axios = require('axios');

interface IState {
    isAuthed: boolean;
}

export class ChartRequest extends Component<any, IState> {
    constructor(props) {
        super(props);
        this.state = { 
            isAuthed: false,
        };
    }

    componentDidMount(){
        axios.get('/auth')
          .then(res => {
              if (res.status == 200){
                  this.setState({isAuthed : true});
              }
              else{
                const { match: { params } } = this.props;
                window.location.href = `${config.SERVICE_BASE_URL}/auth/${params.id}`;
              }
          })
    }

    render() {

        if (!this.state.isAuthed){
            return (null);
        }
        
        const id = this.props.match.params.id;
        return (
            <div>
                <Request id={id} />
            </div>
        );
    }
}

export default ChartRequest;