import React, { Component, createRef, useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { selectFilter, textFilter } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import Moment from 'moment';
import './List.scss'
import { Link } from 'react-router-dom'
const axios = require('axios');

interface IChart {
  chartLocation: string,
  chartType: string,
  specialInstructions: string,
  workingTitle: string,
  titleDate: string,
  amountOf: string,
  chartNotes: string,
  extendedNote: string,
  chartDescription: string,
  dupeOf: string,
  subjects: string,
  addToCE: boolean
}

interface IChartRequest {
  id: string,
  name: string,
  status: number,
  requestDate: string | null,
  requester: string,
  priority: string,
  peopleToAlert: string,
  message: string,
  sourceUrl: string,
  primarySourceLine: string,
  publicationDate: string | null,
  methodology: string,
  miscMethodology: string,
  requestSubmitted: boolean,

  middleman: string,
  writeupBy: string,
  writeUpNotify: boolean,
  writeUpDate: string | null,
  entryBy: string,
  entryNotify: boolean,
  entryByDate: string | null,
  proofBy: string,
  proofNotify: boolean,
  proofByDate: string | null,
  proofDate: string | null,
  factCheck: boolean,
  useInNewsletter: boolean,
  notes: string,
  version: number,
  ccsChartIds: number[],

  charts: IChart[]
}

enum TabsEnum {
  ACTIVE = "active",
  COMPLETED = "completed",
  ALL = "all"
}

interface IState {
  requests: IChartRequestData,
  totalCharts: number,
  loadingData: boolean,
  selectedTab: TabsEnum
}

interface IChartRequestData {
  active: IChartRequest[],
  completed: IChartRequest[],
  all: IChartRequest[],
  visibleData: IChartRequest[]
}

const Loader = () => (
  <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
)

export class List extends Component<{}, IState> {
  constructor(props) {
    super(props);

    this.state = {
      requests: {
        active: [],
        completed: [],
        all: [],
        visibleData: []
      },
      totalCharts: 0,
      loadingData: false,
      selectedTab: TabsEnum.ACTIVE
    };

    this.tableRef = createRef<BootstrapTable>();
  }

  private tableRef: React.RefObject<BootstrapTable>;

  getChartData = (showCompletedRequests = false) => {
    this.setState({ ...this.state, loadingData: true })
    return axios.get(`/getchartrequesttabs?showCompletedRequests=${showCompletedRequests}`)
      .then(res => {
        const data = res.data;
        let newState = {};

        if (showCompletedRequests === false)
          newState = { requests: { ...this.state.requests, active: data } };
        else
          newState = { requests: { ...this.state.requests, completed: data } };

        this.setState(newState);
      })
  }

  async componentDidMount() {
    if (this.state.loadingData === false) {
      await this.getChartData(false)
      this.setState({ requests: { ...this.state.requests, visibleData: this.state.requests.active } })

      await this.getChartData(true)
    }
  }

  render() {
    const customTotal = (from, to, size) => {
      if ((this.tableRef as any).table) {
        var data = (this.tableRef as any).table.props.data;
        var chartCount = 0;

        data.forEach(function (request) {
          chartCount += request.Charts.length;
        })

        if (this.state.totalCharts != chartCount) {
          this.setState({ totalCharts: chartCount });
        }
      }

      return (
        <span className="react-bootstrap-table-pagination-total">
          Showing {from} to {to} of {size} Results
        </span>
      )
    };

    const handleTab = (tab: TabsEnum) => {
      //only allow tab change when all data is loaded
      if (this.state.requests.completed.length > 0) {
        const data = {
          [TabsEnum.ACTIVE]: this.state.requests.active,
          [TabsEnum.COMPLETED]: this.state.requests.completed,
          [TabsEnum.ALL]: [...this.state.requests.active, ...this.state.requests.completed]
        }

        this.setState({ selectedTab: tab, requests: { ...this.state.requests, visibleData: data[tab] } })
      }
    }

    const paginationOptions = {
      showTotal: true,
      paginationTotalRenderer: customTotal
    };

    const statusOptions = {
      0: 'Saved to Draft',
      1: 'Submitted/Unprocessed',
      2: 'At Writeup Not Entered',
      3: 'At Entry',
      4: 'Entered Not Proofed',
      6: 'Completed Request'
    };

    const priorityOptions = {
      0: 'Urgent',
      1: 'Report/Webinar',
      2: 'Newsletter',
      3: 'Client Request',
      4: 'Special Request',
      5: 'Em Forecast/CE',
      6: 'Gmi',
      7: 'Database',
      8: 'Embargoed'
    };

    const boolOptions = {
      true: "Yes",
      false: "No"
    };

    const linkFormat = (name) => {
      const requestId = name.split("-").pop();
      return (
        <Link to={requestId} target="_blank">
          {name}
        </Link>
      )
    }

    const dateFormat = (date) => {
      return date === null ? "" : Moment(date).utc().format('MM/DD/YYYY')
    }

    const chartIdsFormat = (ids) => {
      var chartIds = "";

      if (!ids || !Array.isArray(ids) || ids.length < 1) {
        return chartIds;
      }

      ids.forEach(function (e) {
        chartIds += e + " ";
      })

      return chartIds.slice(0, -1);
    }

    const columns = [{
      dataField: 'RequestDate',
      text: 'Request Date',
      formatter: cell => dateFormat(cell),
      sort: true,
    }, {
      dataField: 'DueDate',
      text: 'Due Date',
      formatter: cell => dateFormat(cell),
      sort: true,
    }, {
      dataField: 'Priority',
      text: 'Priority',
      formatter: cell => priorityOptions[cell],
      filter: selectFilter({
        options: priorityOptions,
      })
    }, {
      dataField: 'Name',
      text: 'Name',
      formatter: cell => linkFormat(cell)
    }, {
      dataField: 'PrimarySourceLine',
      text: 'Primary Source',
      filter: textFilter(),
    }, {
      dataField: 'Requester',
      text: 'Chart Requester',
      filter: textFilter()
    }, {
      dataField: 'Charts',
      text: `# of Charts (Total: ${this.state.totalCharts})`,
      formatter: cell => cell.length
    }, {
      dataField: 'CCSChartIds',
      text: 'Chart File #',
      formatter: cell => chartIdsFormat(cell),
      filter: textFilter()
    }, {
      dataField: 'WriteupBy',
      text: 'Writer Up',
      filter: textFilter()
    }, {
      dataField: 'WriteUpDate',
      text: 'Write Up Date',
      formatter: cell => dateFormat(cell),
      sort: true,
    }, {
      dataField: 'EntryBy',
      text: 'Enter By',
      filter: textFilter()
    }, {
      dataField: 'EntryByDate',
      text: 'Entry By Date',
      formatter: cell => dateFormat(cell),
      sort: true,
    }, {
      dataField: 'ProofBy',
      text: 'Proofer',
      filter: textFilter()
    }, {
      dataField: 'ProofByDate',
      text: 'Proof By Date',
      formatter: cell => dateFormat(cell),
      sort: true,
    }, {
      dataField: 'ProofDate',
      text: 'Proof Completion Date',
      formatter: cell => dateFormat(cell),
      sort: true,
    }, {
      dataField: 'Status',
      text: 'Status',
      formatter: cell => statusOptions[cell],
      filter: selectFilter({
        options: statusOptions,
      })
    }, {
      dataField: 'FactCheck',
      text: 'Fact Check',
      formatter: cell => boolOptions[cell],
      filter: selectFilter({
        options: boolOptions,
      })
    }, {
      dataField: 'UseInNewsletter',
      text: 'Use In Newsletter',
      formatter: cell => boolOptions[cell],
      filter: selectFilter({
        options: boolOptions,
      })
    }];

    const defaultSorted = [{
      dataField: 'RequestDate',
      order: 'desc'
    }];

    return (
      <div>
        <div className="subheader">
          <div className={this.state.requests.completed.length > 0 ? "tabs" : "tabs loading"}>
            <span className={this.state.selectedTab === TabsEnum.ACTIVE ? "active" : ""} onClick={() => handleTab(TabsEnum.ACTIVE)}>
              Active Requests
              <span className="counter">{this.state.requests.active.length}</span>
            </span>
            <span className={this.state.selectedTab === TabsEnum.COMPLETED ? "active completed" : "completed"} onClick={() => handleTab(TabsEnum.COMPLETED)}>
              Completed Requests
              <span className="counter">{this.state.requests.completed.length}</span>
              <span className="loader"><Loader /></span>
            </span>
            <span className={this.state.selectedTab === TabsEnum.ALL ? "active all" : "all"} onClick={() => handleTab(TabsEnum.ALL)}>
              All Requests
              <span className="counter">{this.state.requests.active.length + this.state.requests.completed.length}</span>
              <span className="loader"><Loader /></span>
            </span>
          </div>
          <div className="buttons">
            <Link to="new" target="_blank" className="btn btn-primary">New Chart Request</Link>
          </div>
        </div>

        <BootstrapTable
          ref={n => this.tableRef = n}
          condensed bootstrap4
          keyField='id'
          data={this.state.requests.visibleData}
          columns={columns}
          filter={filterFactory()}
          pagination={paginationFactory(paginationOptions)}
          defaultSorted={defaultSorted}
        />
      </div >
    );
  }
}

export default List;