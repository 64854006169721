import React, { Component } from 'react';
import './ChartForm.scss';

export interface IChartForm {
    chartLocation: string,
    chartType: string,
    specialInstructions: string,
    workingTitle: string,
    titleDate: string,
    amountOf: string,
    chartNotes: string,
    extendedNote: string,
    chartDescription: string,
    dupeOf: string,
    subjects: string,
    addToCE: boolean
}

interface IChartFormProps extends IChartForm {
    key: number
    chartLabel: string
    handleChange(e: any)
    handleRemove()
    disabled: boolean
    isValid(fieldName: string) : boolean
    fieldLength(fieldName: string): number
}

export class ChartForm extends Component<IChartFormProps, any> {
    render() {
        const feedback = (fieldName: string) => this.props.isValid(fieldName) ? '' : 'invalid-feedback font-weight-bold';
        const invalid = (fieldName: string) => this.props.isValid(fieldName) ? '' : 'is-invalid';
        const fieldHint = fieldName => <div className={`fieldHint ${feedback(fieldName)}`}>{this.props[fieldName].length}/{this.props.fieldLength(fieldName)} characters</div>;

        return (
            <div className="container chartForm">
                {this.props.chartLabel}
                <div className="text-center">
                    <button type="button" onClick={this.props.handleRemove} disabled={this.props.disabled} className="btn btn-danger form-group row m-3">Remove Chart</button>
                </div>
                <div className="form-group row">
                    <label htmlFor="chartLocation" className="col-sm-3 col-form-label">Chart Location:</label>
                    <div className="col-sm-9">
                        <input
                        className="form-control"
                        name="chartLocation"
                        type="text"
                        value={this.props.chartLocation}
                        onChange={this.props.handleChange}
                        />
                    </div>
                </div>
                <div className="form-group row">
                    <label htmlFor="chartType" className="col-sm-3 col-form-label">Chart Type:</label>
                    <div className="col-sm-9">
                        <select name="chartType"className="form-control" value={this.props.chartType} onChange={this.props.handleChange} required>
                            <option value="">Select One</option>
                            <option value="0">Compound Horizontal Bar</option>
                            <option value="1">Horizontal Bar</option>
                            <option value="2">Pie</option>
                            <option value="3">Table</option>
                            <option value="4">Vertical Bar</option>
                        </select>
                    </div>
                </div>
                <div className="form-group row">
                    <label htmlFor="specialInstructions" className="col-sm-3 col-form-label">Special Instructions:</label>
                    <div className="col-sm-9">
                        <textarea name="specialInstructions" rows={5} value={this.props.specialInstructions} onChange={this.props.handleChange} className="form-control"></textarea>
                    </div>
                </div>
                <div className="form-group row">
                    <label htmlFor="workingTitle" className="col-sm-3 col-form-label">Working Title:</label>
                    <div className="col-sm-9">
                        <textarea name="workingTitle" rows={5} value={this.props.workingTitle} onChange={this.props.handleChange} className={`form-control ${invalid('workingTitle')}`}></textarea>
                        {fieldHint('workingTitle')}
                    </div>
                </div>
                <div className="form-group row">
                    <label htmlFor="titleDate" className="col-sm-3 col-form-label">Title date:</label>
                    <div className="col-sm-9">
                        <input name="titleDate" value={this.props.titleDate} onChange={this.props.handleChange} className={`form-control ${invalid('titleDate')}`} type="text" />
                        {fieldHint('titleDate')}
                    </div>
                </div>
                <div className="form-group row">
                    <label htmlFor="amountOf" className="col-sm-3 col-form-label">Amount of:<br /></label>
                    <div className="col-sm-9">
                        <input name="amountOf" value={this.props.amountOf} onChange={this.props.handleChange} className={`form-control ${invalid('amountOf')}`} type="text" />
                        {fieldHint('amountOf')}
                    </div>
                </div>
                <div className="form-group row">
                    <label htmlFor="chartNotes" className="col-sm-3 col-form-label">Notes:</label>
                    <div className="col-sm-9">
                        <textarea name="chartNotes" rows={5} value={this.props.chartNotes} onChange={this.props.handleChange} className="form-control"></textarea>
                    </div>
                </div>
                <div className="form-group row">
                    <label htmlFor="extendedNote" className="col-sm-3 col-form-label">Extended Note:</label>
                    <div className="col-sm-9">
                        <textarea name="extendedNote" rows={5} value={this.props.extendedNote} onChange={this.props.handleChange} className="form-control"></textarea>
                    </div>
                </div>
                <div className="form-group row">
                    <label htmlFor="chartDescription" className="col-sm-3 col-form-label">Chart Description:</label>
                    <div className="col-sm-9">
                        <textarea name="chartDescription" rows={5} value={this.props.chartDescription} onChange={this.props.handleChange} className="form-control"></textarea>
                    </div>
                </div>
                <div className="form-group row">
                    <label htmlFor="dupeOf" className="col-sm-3 col-form-label">Dupe of:</label>
                    <div className="col-sm-9">
                        <input name="dupeOf" value={this.props.dupeOf} onChange={this.props.handleChange} className="form-control" type="text" />
                    </div>
                </div>
                <div className="form-group row">
                    <label htmlFor="subjects" className="col-sm-3 col-form-label">Subjects:</label>
                    <div className="col-sm-9">
                        <textarea name="subjects" rows={5} value={this.props.subjects} onChange={this.props.handleChange} className="form-control"></textarea>
                    </div>
                </div>
                <div className="form-group row">
                    <div className="col-sm-4 col-form-label offset-4">
                        <label>
                            <input name="addToCE" checked={this.props.addToCE} onChange={this.props.handleChange} type="checkbox" className="form-check-input"/>Add to CE
                        </label>
                    </div>
                    <div className="col-sm-4 col-form-label">
                        <a target="_blank" rel="noopener noreferrer" href="http://sharepoint.emarketer.com/sites/Charts/_layouts/xlviewer.aspx?id=/sites/Charts/Comparative%20Estimates%20Documents/Metrics%20by%20Category.xlsx&Source=http%3A%2F%2Fsharepoint%2Eemarketer%2Ecom%2Fsites%2FCharts%2FComparative%2520Estimates%2520Documents%2FForms%2FAllItems%2Easpx&DefaultItemOpen=1&DefaultItemOpen=1">Link to CE Topics</a>
                    </div>
                </div>
            </div>
        );
    }
}