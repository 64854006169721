import React, { Component } from 'react';
import Header from './Components/Header/Header';
import { Main } from './routes';

class App extends Component {
  render() {
    return (
      <div className="App">
      <link
        rel="stylesheet"
        href="https://maxcdn.bootstrapcdn.com/bootstrap/latest/css/bootstrap.min.css"
        crossOrigin="anonymous"
      />
        <Header /> 
        <Main />
      </div>
    );
  }
}

export default App;
