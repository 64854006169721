import React, { Component } from 'react';
import Moment from 'moment';

const chartTeamStyle = {
    background: '#C9C9AD',
    minHeight:"825px"
};

export interface IChartsTeamForm {
    middleman: string,
    writeupBy: string,
    writeUpNotify: boolean,
    writeUpDate: string | null,
    entryBy: string,
    entryNotify: boolean,
    entryByDate: string | null,
    proofBy: string,
    proofNotify: boolean,
    proofByDate: string | null,
    proofDate: string | null,
    factCheck: boolean,
    useInNewsletter: boolean,
    notes: string,
    ccsChartIds: number[],
}

interface IChartsTeamFormProps extends IChartsTeamForm {
    handleChange(e: any)
    handleSubmit(e: any)
    ableToSave: boolean
    saveButtonText: string
    saveToCcsErrorMsg: string
}

export class ChartsTeamForm extends Component<IChartsTeamFormProps, any> {
    render() {
        const formatDate = (dateString, formatString) => dateString == null ? "" : Moment(dateString).utc().format(formatString);
        const writeUpDateFormatted = formatDate(this.props.writeUpDate, 'MM/DD/YYYY');
        const entryByDateFormatted = formatDate(this.props.entryByDate, 'MM/DD/YYYY');
        const proofByDateFormatted = formatDate(this.props.proofByDate, 'MM/DD/YYYY');
        const proofDateFormatted = formatDate(this.props.proofDate, 'YYYY-MM-DD');

        const chartIds = this.props.ccsChartIds.join(' ');
        const handleCheckboxChange = e => this.props.handleChange({target: {name: e.target.name, value: e.target.checked}});

        return (
            <div className="col-lg-12" style={chartTeamStyle}>
                <h4 className="text-center m-2">FOR CHARTS TEAM ONLY</h4>
                <div className="form-group row">
                    <label htmlFor="middleman" className="col-sm-3 col-form-label">Middleman:</label>
                    <div className="col-sm-9">
                        <select name="middleman" value={this.props.middleman} required className="form-control" onChange={this.props.handleChange}>
                            <option value="">Select One</option>
                            <option value="plin@emarketer.com">plin@emarketer.com</option>
                            <option value="dgibson@emarketer.com">dgibson@emarketer.com</option>
                            <option value="mtorpey@emarketer.com">mtorpey@emarketer.com</option>
                        </select>
                    </div>
                </div>
                <div className="form-group row">
                    <label htmlFor="writeupBy" className="col-sm-3 col-form-label">Write up by:</label>
                    <div className="col-sm-9">
                        <input name="writeupBy" value={this.props.writeupBy}  className="form-control" type="email" onChange={this.props.handleChange} />
                    </div>
                </div>
                <div className="form-group row">
                    <label className="col-5 col-form-label offset-4">
                        <input name="writeUpNotify" className="form-check-input" checked={this.props.writeUpNotify}  type="checkbox" onChange={handleCheckboxChange}/>
                        Notify
                    </label>
                    <span className="col-3 col-form-label">{writeUpDateFormatted}</span>
                </div>
                <div className="form-group row">
                    <label htmlFor="entryBy" className="col-sm-3 col-form-label">Entry by:</label>
                    <div className="col-sm-9">
                        <input name="entryBy" value={this.props.entryBy} className="form-control" type="email" onChange={this.props.handleChange}/>
                    </div>
                </div>
                <div className="form-group row">
                    <label className="col-5 col-form-label offset-4">
                        <input name="entryNotify" className="form-check-input" checked={this.props.entryNotify} type="checkbox" onChange={handleCheckboxChange}/>
                        Notify
                    </label>
                    <span className="col-3 col-form-label">{entryByDateFormatted}</span>
                </div>
                <div className="form-group row">
                    <label htmlFor="proofBy" className="col-sm-3 col-form-label">Proof by:</label>
                    <div className="col-sm-9">
                        <input name="proofBy" value={this.props.proofBy} className="form-control" type="email" onChange={this.props.handleChange}/>
                    </div>
                </div>
                <div className="form-group row">
                    <label className="col-5 col-form-label offset-4">
                        <input name="proofNotify" className="form-check-input" checked={this.props.proofNotify} type="checkbox" onChange={handleCheckboxChange}/>
                        Notify
                    </label>
                    <span className="col-3 col-form-label">{proofByDateFormatted}</span>
                </div>
                <div className="form-group row">
                    <label htmlFor="proofDate" className="col-sm-5 col-form-label">Proof Completion Date:</label>
                    <div className="col-sm-7">
                        <input name='proofDate' value={proofDateFormatted} className="form-control" type="date" onChange={this.props.handleChange}/>
                    </div>
                </div>
                <div className="form-group row">
                    <label className="col-7 col-form-label offset-4">
                        <input name="factCheck" className="form-check-input" checked={this.props.factCheck}  type="checkbox" onChange={handleCheckboxChange}/>
                        Factcheck
                    </label>
                </div>
                <div className="form-group row">
                    <label className="col-7 col-form-label offset-4">
                        <input name="useInNewsletter" className="form-check-input" checked={this.props.useInNewsletter}  type="checkbox" onChange={handleCheckboxChange}/>
                        Use in Newsletter
                    </label>
                </div>
                <div className="form-group row">
                    <label htmlFor="notes" className="col-sm-3 col-form-label">Notes:</label>
                    <div className="col-sm-9">
                        <textarea name='notes' rows={5} value={this.props.notes} className="form-control" onChange={this.props.handleChange}></textarea>
                    </div>
                </div>
                <div className="form-group row">
                    <div className="col-9 offset-3">
                        <button className="btn btn-primary" onClick={this.props.handleSubmit} disabled={this.props.ccsChartIds.length > 0 || !this.props.ableToSave}>{this.props.saveButtonText}</button>
                    </div>
                </div>
                {this.props.saveToCcsErrorMsg ?
                <div className="form-group row">
                    <div className="col-9 offset-3">
                        <div className="alert alert-danger" role="alert">
                            {this.props.saveToCcsErrorMsg}
                        </div>
                    </div>
                </div> : null}
                <div className="form-group row">
                    <div className="col-9 offset-3">
                        <input type="text" name='ccsChartIds' value={chartIds} readOnly className="field left form-control bg-secondary text-white"></input>
                    </div>
                </div>
            </div>
        );
    }
}