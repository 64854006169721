import React, { Component, isValidElement } from 'react';
import axios from 'axios';
import { createHashHistory } from 'history';
import './Request.scss';
import {RequesterForm, IRequesterForm} from '../Requester/RequesterForm';
import {ChartsTeamForm, IChartsTeamForm} from '../ChartsTeamForm/ChartsTeamForm';
import {ChartForm, IChartForm} from '../ChartForm/ChartForm';
import { request } from 'https';

export const history = createHashHistory()

interface IProps {
    id: string
}

const maxFieldLengths = {
    sourceUrl: 1000,
    primarySourceLine: 500
}

const chartMaxFieldLengths = {
    workingTitle: 255,
    titleDate: 500,
    amountOf: 500
}

const validateEmail = email => {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return email === null || email.length === 0 || re.test(String(email).toLowerCase());
}

const isFieldValid = (fieldName, fieldValue, maxFieldLengths) => {
    if (fieldName in maxFieldLengths) {
        const maxlen = maxFieldLengths[fieldName];
        const fieldlen = fieldValue === undefined || fieldValue === null ? 0 : fieldValue.length;
        if (maxlen > 0 && fieldlen > maxlen) {
            return false;
        }
    }

    if (fieldName === 'peopleToAlert' && fieldValue) {
        const emailAddresses = fieldValue.split(/;|,/);
        if (emailAddresses.some(email => !validateEmail(email.trim())))
        {
            return false;
        }
    }

    // handle other validation here, if necessary

    return true;
}

interface IState {
    id: string,
    name: string,
    status: number,
    requesterState: IRequesterForm,
    chartsTeamState: IChartsTeamForm,
    charts: IChartForm[],
    version: number,

    savingToCcs: boolean,
    saveToCcsErrorMsg: string,
    saving: boolean,
    copying: boolean,
    dirty: boolean
}

export class Request extends Component<IProps, IState>  {
    constructor(props) {
        super(props);

        this.state = { 
            id: this.props.id,
            name: '',
            status: 0,
            requesterState: {
                requestDate: null,
                dueDate: null,
                requester: '',
                requesters: [],
                priority: '',
                peopleToAlert: '',
                message: '',
                sourceUrl: '',
                primarySourceLine: '',
                publicationDate: null,
                methodology: '',
                miscMethodology: '',
                requestSubmitted: false,
            },
            chartsTeamState: {
                middleman: 'plin@emarketer.com',
                writeupBy: '',
                writeUpNotify: false,
                writeUpDate: null,
                entryBy: '',
                entryNotify: false,
                entryByDate: null,
                proofBy: '',
                proofNotify: false,
                proofByDate: null,
                proofDate: null,
                factCheck: false,
                useInNewsletter: false,
                notes: '',
                ccsChartIds: [],
            },
            charts: [
                {
                    chartLocation: '',
                    chartType: '',
                    specialInstructions: '',
                    workingTitle: '',
                    titleDate: '',
                    amountOf: '',
                    chartNotes: '',
                    extendedNote: '',
                    chartDescription: '',
                    dupeOf: '',
                    subjects: '',
                    addToCE: false
                }
            ],

            version: 0,
            savingToCcs: false,
            saveToCcsErrorMsg: '',
            saving: false,
            copying: false,
            dirty: false
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleRequesterFormChange = this.handleRequesterFormChange.bind(this);
        this.handleChartsTeamFormChange = this.handleChartsTeamFormChange.bind(this);
        this.handleChartChange = this.handleChartChange.bind(this);
        this.handleRemoveChart = this.handleRemoveChart.bind(this);
        this.handleAddChart = this.handleAddChart.bind(this);
        this.handleCopyRequest = this.handleCopyRequest.bind(this);
        this.handleCCSSubmit = this.handleCCSSubmit.bind(this);

        this.savedToCcs = this.savedToCcs.bind(this);
        this.isFormValid = this.isFormValid.bind(this);
      }

    componentDidMount() {
        const { id } = this.props;

        if (id) {
            axios.get('/getchartrequest/' + id)
            .then(res => {
              const data = res.data.Request;
              const requesters = res.data.Requesters.map(requester => ({
                  email: requester.Email,
                  fullName: requester.FullName
              }));

              const chartData = data.Charts.map(chart => ({
                    chartLocation: chart.ChartLocation,
                    chartType: chart.ChartType,
                    specialInstructions: chart.SpecialInstructions,
                    workingTitle: chart.WorkingTitle,
                    titleDate: chart.TitleDate,
                    amountOf: chart.AmountOf,
                    chartNotes: chart.ChartNotes,
                    extendedNote: chart.ExtendedNote,
                    chartDescription: chart.ChartDescription,
                    dupeOf: chart.DupeOf,
                    subjects: chart.Subjects,
                    addToCE: chart.AddToCE
              }));

              this.setState({
                id: data.Id,
                name: data.Name,
                status: data.Status,
                requesterState: {
                    requestDate: data.RequestDate,
                    dueDate: data.DueDate,
                    requester: data.Requester,
                    requesters: requesters,
                    priority: data.Priority,
                    peopleToAlert: data.PeopleToAlert,
                    message: data.Message,
                    sourceUrl: data.SourceUrl,
                    primarySourceLine: data.PrimarySourceLine,
                    publicationDate: data.PublicationDate,
                    methodology: data.Methodology,
                    miscMethodology: data.MiscMethodology,
                    requestSubmitted: data.RequestSubmitted
                },
                chartsTeamState: {
                    middleman: data.Middleman,
                    writeupBy: data.WriteupBy,
                    writeUpNotify: data.WriteUpNotify,
                    writeUpDate: data.WriteUpDate,
                    entryBy: data.EntryBy,
                    entryNotify: data.EntryNotify,
                    entryByDate: data.EntryByDate,
                    proofBy: data.ProofBy,
                    proofNotify: data.ProofNotify,
                    proofByDate: data.ProofByDate,
                    proofDate: data.ProofDate,
                    factCheck: data.FactCheck,
                    useInNewsletter: data.UseInNewsletter,
                    notes: data.Notes,
                    ccsChartIds: data.CCSChartIds || []
                },
                charts: chartData,
                version: data.Version
              });
            });
        } else {
            axios.get('/getchartstaff')
            .then(res => {
              const requesters = res.data.Requesters.map(requester => ({
                  email: requester.Email,
                  fullName: requester.FullName
              }));

              this.setState({
                requesterState: {
                    ...this.state.requesterState,
                    requesters: requesters,
                }
              });
            });
        }
    }

    isFormValid() {
        const allFieldsValid = (state, maxFieldLengths) => Object.keys(state).every(fieldName => isFieldValid(fieldName, state[fieldName], maxFieldLengths));
        const lengthValid = allFieldsValid(this.state.requesterState, maxFieldLengths) && this.state.charts.every(chart => allFieldsValid(chart, chartMaxFieldLengths));

        return lengthValid;
    }

    handleRequesterFormChange(e) {
        this.setState({
            dirty: true,
            requesterState: {
                ...this.state.requesterState,
                [e.target.name]: e.target.value
            }
        });
    }

    handleChartsTeamFormChange(e) {
        this.setState({
            dirty: true,
            chartsTeamState: {
                ...this.state.chartsTeamState,
                [e.target.name]: e.target.value
            }
        });
    }

    handleChartChange = (idx) => (evt) => {
        const newCharts = this.state.charts.map((chart, sidx) => {
          if (idx !== sidx) return chart;
          const value = evt.target.type === 'checkbox' ? evt.target.checked : evt.target.value;
          return {
              ...chart,
              [evt.target.name]: value
            };
        });

        this.setState({
            dirty: true,
            charts: newCharts
        });
    }

    handleAddChart = () => {
        if (this.savedToCcs()) {
            alert("cannot add charts after saving to CCS");
            return;
        }

        this.setState({dirty: true});

        if (this.state.charts.length > 0) {
            this.setState({
                charts: this.state.charts.concat([{
                    chartLocation: this.state.charts[0].chartLocation,
                    chartType: this.state.charts[0].chartType,
                    specialInstructions: this.state.charts[0].specialInstructions,
                    workingTitle: this.state.charts[0].workingTitle,
                    titleDate: this.state.charts[0].titleDate,
                    amountOf: this.state.charts[0].amountOf,
                    chartNotes: this.state.charts[0].chartNotes,
                    extendedNote: this.state.charts[0].extendedNote,
                    chartDescription: this.state.charts[0].chartDescription,
                    dupeOf: this.state.charts[0].dupeOf,
                    subjects: this.state.charts[0].subjects,
                    addToCE: this.state.charts[0].addToCE
                }])
            });
        } else {
            this.setState({
                charts: this.state.charts.concat([{
                    chartLocation: '',
                    chartType: '',
                    specialInstructions: '',
                    workingTitle: '',
                    titleDate: '',
                    amountOf: '',
                    chartNotes: '',
                    extendedNote: '',
                    chartDescription: '',
                    dupeOf: '',
                    subjects: '',
                    addToCE: false
                }])
            });         
        }
    }

    handleRemoveChart = (idx) => () => {
        if (this.savedToCcs()) {
            alert("cannot remove charts after saving to CCS");
            return;
        }

        this.setState({
            dirty: true,
            charts: this.state.charts.filter((s, sidx) => idx !== sidx)
        });
    }

    savedToCcs() {
        return this.state.chartsTeamState.ccsChartIds.length > 0;
    }

    handleSubmit(e) {
        e.preventDefault();

        if (!this.isFormValid()) {
            alert('Some fields are invalid. Please correct them before saving.');
            return;
        }

        const data = {
            id: this.props.id,
            name: this.state.name,
            status: this.state.status,
            requestDate: this.state.requesterState.requestDate,
            dueDate: this.state.requesterState.dueDate,
            requester: this.state.requesterState.requester,
            priority: this.state.requesterState.priority,
            peopleToAlert: this.state.requesterState.peopleToAlert,
            message: this.state.requesterState.message,
            sourceUrl: this.state.requesterState.sourceUrl,
            primarySourceLine: this.state.requesterState.primarySourceLine,
            publicationDate: this.state.requesterState.publicationDate,
            methodology: this.state.requesterState.methodology,
            miscMethodology: this.state.requesterState.miscMethodology,
            requestSubmitted: this.state.requesterState.requestSubmitted,
            middleman: this.state.chartsTeamState.middleman,
            writeupBy: this.state.chartsTeamState.writeupBy,
            writeUpNotify: this.state.chartsTeamState.writeUpNotify,
            writeUpDate: this.state.chartsTeamState.writeUpDate,
            entryBy: this.state.chartsTeamState.entryBy,
            entryNotify: this.state.chartsTeamState.entryNotify,
            entryByDate: this.state.chartsTeamState.entryByDate,
            proofBy: this.state.chartsTeamState.proofBy,
            proofNotify: this.state.chartsTeamState.proofNotify,
            proofByDate: this.state.chartsTeamState.proofByDate,
            proofDate: this.state.chartsTeamState.proofDate,
            factCheck: this.state.chartsTeamState.factCheck,
            useInNewsletter: this.state.chartsTeamState.useInNewsletter,
            notes: this.state.chartsTeamState.notes,
            ccsChartIds: this.state.chartsTeamState.ccsChartIds,
            version: this.state.version,
            charts: this.state.charts,
        }

        this.setState({saving: true});

        axios('/createchartrequest',{
            method: 'POST',
            data : JSON.stringify(data),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        }).then(response => {
            if (response.data.invalidEmails && response.data.invalidEmails.length > 0) {
                alert(`Not sending email to the following addresses because they are invalid: ${response.data.invalidEmails.join('\n')}`);
            }
            const id = response.data.Id;
            this.setState({saving: false, dirty: false});
            alert("Save Successful!");
            history.push("/" + id);
            window.location.reload();
        }).catch(error => {
            this.setState({saving: false});
            alert(error);
        });
    }

    handleCopyRequest(e) {
        e.preventDefault();

        const data = {
            name: this.state.name,
            requestDate: this.state.requesterState.requestDate,
            requester: this.state.requesterState.requester,
            priority: this.state.requesterState.priority,
            peopleToAlert: this.state.requesterState.peopleToAlert,
            message: this.state.requesterState.message,
            sourceUrl: this.state.requesterState.sourceUrl,
            primarySourceLine: this.state.requesterState.primarySourceLine,
            publicationDate: this.state.requesterState.publicationDate,
            methodology: this.state.requesterState.methodology,
            miscMethodology: this.state.requesterState.miscMethodology,
            middleman: this.state.chartsTeamState.middleman,
            charts: this.state.charts,
            ccsChartIds: []
        }

        this.setState({copying: true});

        axios('/copychartrequest',{
            method: 'POST',
            data : JSON.stringify(data),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        }).then(response => {
            const id = response.data.Id;
            this.setState({copying: false});
            alert("Copy Successful!");
            history.push("/" + id);
            window.location.reload();
        }).catch(error => {
            this.setState({copying: false});
            alert(error);
        });
    }

    handleCCSSubmit(e) {
        e.preventDefault();

        if (this.state.dirty) {
            alert('Form is unsaved. Please save the form before saving to CCS.');
            return;
        }

        if (!this.isFormValid()) {
            alert('Some fields are invalid. Please correct them before saving to CCS.');
            return;
        }

        this.setState({savingToCcs: true, saveToCcsErrorMsg: ''});

        const requester = this.state.requesterState.requesters.find(r => r.email === this.state.requesterState.requester) || '';
        const requesterFullName = requester ? requester.fullName : '';

        const data = {
            id: this.props.id,
            methodology: this.state.requesterState.methodology,
            miscMethodology: this.state.requesterState.miscMethodology,
            primarySourceLine: this.state.requesterState.primarySourceLine,
            sourceUrl: this.state.requesterState.sourceUrl,
            publicationDate: this.state.requesterState.publicationDate,
            requester: requesterFullName,
            version: this.state.version,
            charts: this.state.charts
        }

        axios('/SaveToCCS',{
            method: 'POST',
            data : JSON.stringify(data),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            if (response.status < 200 || response.status >= 300) {
                throw Error(response.statusText);
            }

            if (response.data.success) {
                const id = response.data.Id;
                history.push("/" + id);
                window.location.reload();
                return;
            }

            this.setState({savingToCcs: false, saveToCcsErrorMsg: `error saving to CCS: ${response.data.errorMsgs}`});
        }).catch((error) => {
            console.log(error);
            this.setState({savingToCcs: false, saveToCcsErrorMsg: `error saving to CCS: ${error}`});
        });
    }

    render() {
        const actionPending = this.state.saving || this.state.savingToCcs || this.state.copying;
        const saveCopyCloseButtons = header => (
            <div className="container text-center">
                {header ? <h1>Chart Request</h1> : null}
                <button type="submit" className="col-3 btn btn-primary m-2" disabled={actionPending}>{this.state.saving ? 'Saving...' : 'Save'}</button>
                <button onClick={this.handleCopyRequest} className="col-3 btn btn-secondary text-white m-2" disabled={this.state.id === undefined || actionPending}>{this.state.copying ? 'Copying...' : 'Copy'}</button>
                <a href="#" onClick={e => { e.preventDefault(); if (!this.state.dirty || confirm("The form is unsaved. Are you sure you want to close?")) window.close(); }} className="col-3 btn btn-danger m-2">Close</a>
            </div>
        );

        return (
            <div>
                <form onSubmit={this.handleSubmit}>
                    {saveCopyCloseButtons(true)}
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <RequesterForm {...this.state.requesterState} handleChange={this.handleRequesterFormChange} isValid={fieldName => isFieldValid(fieldName, this.state.requesterState[fieldName], maxFieldLengths)} fieldLength={fieldName => maxFieldLengths[fieldName]} />
                            </div>
                            <div className="col-lg-6">
                                <ChartsTeamForm {...this.state.chartsTeamState} handleChange={this.handleChartsTeamFormChange} handleSubmit={this.handleCCSSubmit} ableToSave={!actionPending} saveButtonText={this.state.savingToCcs ? 'Saving to CCS...' : 'Save to CCS'} saveToCcsErrorMsg={this.state.saveToCcsErrorMsg} />
                            </div>
                        </div>
                    </div>
                    <div>
                    <h4 className="text-center m-2">FOR EACH CHART IN BATCH</h4>
                        {this.state.charts.map((chart, idx) => (
                            <ChartForm key={idx} {...chart} chartLabel={`Chart #${idx + 1}`} handleChange={this.handleChartChange(idx)} handleRemove={this.handleRemoveChart(idx)} disabled={actionPending} isValid={fieldName => isFieldValid(fieldName, chart[fieldName], chartMaxFieldLengths)} fieldLength={fieldName => chartMaxFieldLengths[fieldName]} />
                        ))}
                        <div className="text-center">
                            <button type="button" onClick={this.handleAddChart} disabled={actionPending} className="btn btn-primary form-group row">Add Chart</button>
                        </div>
                    </div>
                    {saveCopyCloseButtons(false)}
                </form>
            </div>
        );
    }
}

export default Request;