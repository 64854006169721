import React, { Component } from 'react';
import Moment from 'moment';
import './RequesterForm.scss';

export interface IStaff {
    email: string,
    fullName: string
}

export interface IRequesterForm {
    requestDate: string | null,
    dueDate: string | null,
    requester: string,
    requesters: Array<IStaff>,
    priority: string,
    peopleToAlert: string,
    message: string,
    sourceUrl: string,
    primarySourceLine: string,
    publicationDate: string | null,
    methodology: string,
    miscMethodology: string,
    requestSubmitted: boolean
}

interface IRequesterFormProps extends IRequesterForm {
    handleChange(e: any)
    isValid(fieldName: string): boolean
    fieldLength(fieldName: string): number
}

export class RequesterForm extends Component<IRequesterFormProps, any> {
    render() {
        const formatDate = (dateString, formatString) => dateString == null ? "" : Moment(dateString).utc().format(formatString);
        const requestDateFormatted = formatDate(this.props.requestDate, 'YYYY-MM-DD');
        const dueDateFormatted = formatDate(this.props.dueDate, 'YYYY-MM-DD');
        const publicationDateFormatted = formatDate(this.props.publicationDate, 'YYYY-MM-DD');
        const feedback = fieldName => this.props.isValid(fieldName) ? '' : 'invalid-feedback font-weight-bold';
        const invalid = fieldName => this.props.isValid(fieldName) ? '' : 'is-invalid';
        const fieldHintMaxChars = fieldName => <div className={`fieldHint ${feedback(fieldName)}`}>{this.props[fieldName].length}/{this.props.fieldLength(fieldName)} characters</div>;
        const fieldHintEmail = fieldName => this.props.isValid(fieldName) ? null: <div className={`fieldHint invalid-feedback font-weight-bold`}>Please enter valid email addresses separated by ','</div>;

        return (
        <div className="col-lg-12 requesterForm">
            <h4 className="text-center m-2">TO BE COMPLETED BY REQUESTER</h4>
            <div className="form-group row">
                <label htmlFor="requestDate" className="col-sm-3 col-form-label">Request Date:</label>
                <div className="col-sm-9">
                    <input name="requestDate" value={requestDateFormatted} className="form-control" type="date" onChange={this.props.handleChange}/>
                </div>
            </div>
            <div className="form-group row">
                <label htmlFor="requester" className="col-sm-3 col-form-label">Requester:</label>
                <div className="col-sm-9">
                    <select name="requester" value={this.props.requester} required className="form-control" onChange={this.props.handleChange}>
                        <option value="">Select One</option>
                        {this.props.requesters.map((r, i) => <option value={r.email} key={i}>{r.fullName} &lt;{r.email}&gt;</option>)}
                    </select>
                </div>
            </div>
            <div className="form-group row">
                <label htmlFor="priority" className="col-sm-3 col-form-label">Priority:</label>
                <div className="col-sm-9">
                    <select name="priority" value={this.props.priority} required className="form-control" onChange={this.props.handleChange}>
                        <option value="">Select One</option>
                        <option value="0">Urgent</option>
                        <option value="1">Report/Webinar</option>
                        <option value="2">Newsletter</option>
                        <option value="3">Client Request</option>
                        <option value="4">Special Request</option>
                        <option value="5">eM Forecast/CE</option>
                        <option value="6">GMI</option>
                        <option value="7">Database</option>
                        <option value="8">Embargoed</option>
                    </select>
                </div>
            </div>
            <div className="form-group row">
                <label htmlFor="peopleToAlert" className="col-sm-3 col-form-label">People to Alert:</label>
                <div className="col-sm-9">
                    <input name="peopleToAlert" value={this.props.peopleToAlert} className={`form-control ${invalid('peopleToAlert')}`} type="text" placeholder="Enter emails separated by ','" onChange={this.props.handleChange} />
                    {fieldHintEmail('peopleToAlert')}
                </div>
            </div>
            <div className="form-group row">
                <label htmlFor="dueDate" className="col-sm-3 col-form-label">Due Date:</label>
                <div className="col-sm-9">
                    <input name="dueDate" value={dueDateFormatted} className="form-control" type="date" onChange={this.props.handleChange}/>
                </div>
            </div>
            <div className="form-group row">
                <label htmlFor="message" className="col-sm-3 col-form-label">Message:</label>
                <div className="col-sm-9">
                    <textarea name="message" rows={5} value={this.props.message} className="form-control" onChange={this.props.handleChange}></textarea>
                </div>
            </div>
            <div className="form-group row">
                <label htmlFor="sourceUrl" className="col-sm-3 col-form-label">Source URL:</label>
                <div className="col-sm-9">
                    <textarea name="sourceUrl" rows={5} value={this.props.sourceUrl} className={`form-control ${invalid('sourceUrl')}`} onChange={this.props.handleChange}></textarea>
                    {fieldHintMaxChars('sourceUrl')}
                </div>
            </div>
            <div className="form-group row">
                <label htmlFor="primarySourceLine" className="col-sm-3 col-form-label">Primary Source Line:</label>
                <div className="col-sm-9">
                    <textarea name="primarySourceLine" rows={5} value={this.props.primarySourceLine} className={`form-control ${invalid('primarySourceLine')}`} onChange={this.props.handleChange}></textarea>
                    {fieldHintMaxChars('primarySourceLine')}
                </div>
            </div>
            <div className="form-group row">
                <label htmlFor="publicationDate" className="col-sm-3 col-form-label">Publication Date:</label>
                <div className="col-sm-9">
                    <input name="publicationDate" value={publicationDateFormatted} className="form-control" type="date" onChange={this.props.handleChange} />
                </div>
            </div>
            <div className="form-group row">
                <label htmlFor="methodology" className="col-sm-3 col-form-label">Methodology paragraph: </label>
                <div className="col-sm-9">
                    <textarea name="methodology" rows={5} value={this.props.methodology} className="form-control" onChange={this.props.handleChange}></textarea>
                </div>
            </div>
            <div className="form-group row">
                <label htmlFor="miscMethodology" className="col-sm-3 col-form-label">Misc. methodology note(s):</label>
                <div className="col-sm-9">
                    <textarea name="miscMethodology" rows={5} value={this.props.miscMethodology} className="form-control" onChange={this.props.handleChange}></textarea>
                </div>
            </div>
            <div className="form-group row">
                <label className="col-8 col-form-label offset-4">
                    <input name="requestSubmitted" checked={this.props.requestSubmitted} type="checkbox" className="form-check-input" onChange={e => this.props.handleChange({target: {name: e.target.name, value: e.target.checked}})}/>
                    Submit Request
                </label>
            </div>
        </div>);
    }
}