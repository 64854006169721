import * as React from 'react';
import { Component } from 'react';
import { List } from '../../Components/List/List';
import config from '../../config';

const axios = require('axios');

interface IState {
    isAuthed: boolean;
}

export class ChartRequestList extends Component<{}, IState> {
    constructor(props) {
        super(props);
        this.state = { 
            isAuthed: false,
        };
    }

    componentDidMount(){
        axios.get('/auth')
          .then(res => {
              if (res.status == 200){
                  this.setState({isAuthed : true});
              }
              else{
                window.location.href = `${config.SERVICE_BASE_URL}/auth/list`;
              }
          })
    }

    render() {

        if (!this.state.isAuthed){
            return (null);
        }

        return (
            <div>
                <List />
            </div>
        );
    }
}

export default ChartRequestList;