const DEBUG = false;

const SERVICE_BASE_URL = process.env.REACT_APP_SERVICE_BASE_URL!
const DD_ENABLED = process.env.REACT_APP_DD_ENABLED
const DD_VERSION = process.env.REACT_APP_DD_VERSION!
const DD_SERVICE = process.env.REACT_APP_DD_SERVICE!
const DD_ENV = process.env.REACT_APP_DD_ENV!
const DD_BROWSER_SAMPLE_RATE = Number(process.env.REACT_APP_DD_BROWSER_SAMPLE_RATE)
const DD_TRACE_SAMPLE_RATE = Number(process.env.REACT_APP_DD_TRACE_SAMPLE_RATE)
const DD_APPLICATION_ID = process.env.REACT_APP_DD_APPLICATION_ID!
const DD_CLIENT_TOKEN = process.env.REACT_APP_DD_CLIENT_TOKEN!
const DD_ALLOWED_TRACING_ORIGIN = process.env.REACT_APP_DD_ALLOWED_TRACING_ORIGIN!

if (DEBUG) {
    console.log(`proess.env values`)
    console.log(`SERVICE_BASE_URL: ${process.env.REACT_APP_SERVICE_BASE_URL!}`)
    console.log(`REACT_APP_DD_ENABLED: ${process.env.REACT_APP_DD_ENABLED}`)
    console.log(`REACT_APP_DD_VERSION: ${process.env.REACT_APP_DD_VERSION!}`)
    console.log(`REACT_APP_DD_SERVICE: ${process.env.REACT_APP_DD_SERVICE!}`)
    console.log(`REACT_APP_DD_ENV: ${process.env.REACT_APP_DD_ENV!}`)
    console.log(`REACT_APP_DD_BROWSER_SAMPLE_RATE: ${process.env.REACT_APP_DD_BROWSER_SAMPLE_RATE}`)
    console.log(`REACT_APP_DD_TRACE_SAMPLE_RATE: ${process.env.REACT_APP_DD_TRACE_SAMPLE_RATE}`)
    console.log(`REACT_APP_DD_APPLICATION_ID: ${process.env.REACT_APP_DD_APPLICATION_ID!}`)
    console.log(`REACT_APP_DD_CLIENT_TOKEN: ${process.env.REACT_APP_DD_CLIENT_TOKEN!}`)
    console.log(`REACT_APP_DD_ALLOWED_TRACING_ORIGIN: ${process.env.REACT_APP_DD_ALLOWED_TRACING_ORIGIN!}`)

    console.log(`config values`)
    console.log(`SERVICE_BASE_URL: ${SERVICE_BASE_URL}`)
    console.log(`DD_ENABLED: ${DD_ENABLED}`)
    console.log(`DD_VERSION: ${DD_VERSION}`)
    console.log(`DD_SERVICE: ${DD_SERVICE}`)
    console.log(`DD_ENV: ${DD_ENV}`)
    console.log(`DD_BROWSER_SAMPLE_RATE: ${DD_BROWSER_SAMPLE_RATE}`)
    console.log(`DD_TRACE_SAMPLE_RATE: ${DD_TRACE_SAMPLE_RATE}`)
    console.log(`DD_APPLICATION_ID: ${DD_APPLICATION_ID}`)
    console.log(`DD_CLIENT_TOKEN: ${DD_CLIENT_TOKEN!}`)
    console.log(`DD_ALLOWED_TRACING_ORIGIN: ${DD_ALLOWED_TRACING_ORIGIN}`)
}

const config = {
    SERVICE_BASE_URL,
    DD_ENABLED,
    DD_VERSION,
    DD_SERVICE,
    DD_ENV,
    DD_BROWSER_SAMPLE_RATE,
    DD_TRACE_SAMPLE_RATE,
    DD_APPLICATION_ID,
    DD_CLIENT_TOKEN,
    DD_ALLOWED_TRACING_ORIGIN
};

export default config;

