import React, { Component } from 'react';
import logo from './InsiderIntelligence_Black-CRF.png';
import './Header.scss';

class Header extends Component<{}, {}> {
  render() {
    return (
      <header className="header">
        <img src={logo} className="App-logo" alt="logo" />
        <h1>Chart Requests</h1>
      </header>
    );
  }
}

export default Header;