import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ChartRequestList } from './Containers/RequestList/ChartRequestList';
import { ChartRequest } from './Containers/Request/ChartRequest';

// https://reacttraining.com/react-router/web/guides/quick-start react-router-dom. they rewrite some stuff

export const Main = () => (
  <main>
    <Switch>
      <Route path="/new" component={ChartRequest} />
      <Route path="/:id" component={ChartRequest} />
      <Route path="/*" component={ChartRequestList} />
    </Switch>
  </main>
);